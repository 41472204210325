import React from 'react'
import i18n from 'src/i18n'

function HeaderHome () {
	return (
		<>
			<img className={'logo-rainntech'}
				 alt={'logo-RAInnTech'}
				 src={'/logo.svg'}
			 />
			{/*<div className={'title-rainntech'}>*/}
			{/*	/!*<div>*!/*/}
			{/*	/!*	<h3 className={'title'}>{i18n.t('abbreviation')}</h3>*!/*/}
			{/*	/!*</div>*!/*/}
			{/*	<p className={'motto'}>*/}
			{/*		<span>*/}
			{/*			{i18n.t('motto')}*/}
			{/*		</span>*/}
			{/*	</p>*/}
			{/*</div>*/}
		</>
	)
}

export default HeaderHome
