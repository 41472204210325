import React from 'react'
import { Row, TabContent, TabPane } from 'reactstrap'
import i18n from 'src/i18n'
import ImageGallery from 'react-image-gallery'
import { Helmet } from 'react-helmet'

function Experiences (props) {
	// There are 3 'hacks' in translations.json to allow to use this component as a perfect template without knowing some information
	// The first is for 'technos' and 'more' keys where \n represents a delimiter to split on.
	// Thanks to that we don't have to know how many values we could have for these keys because we can loop on it.
	// The second is 'href' which allow us to have website url different from company name
	// THe third is 'portfolio.imgs' which allow us to have multiple images for the portfolio

	const renderTabPane = (company, key) => {
		const renderPortfolioImgs = () => {
			let imageGallery;
			let imgs = i18n.t(`experiences.companies.${company}.portfolio.imgs`, "").split('\n');

			if (imgs.length === 0 || !imgs[0]) {
				return null
			}

			imgs = imgs.map((img) => {
				const [src, caption] = img.split(':');
				try {
					return {
						original: `/portfolio/${src}`,
						originalTitle: caption,
						originalAlt: caption,
						description: caption
					}
				} catch (e) {
					return null
				}
			})

			return (
				<div className={'portfolio ml-auto mr-auto'}>
					<Row className={'col-md-12 fourth-color'}>
						<h3>
							{i18n.t(`experiences.companies.${company}.portfolio.label`)}
						</h3>
					</Row>
					<div>
						{/* eslint-disable-next-line no-return-assign */}
						<ImageGallery ref={imgGallery => imageGallery = imgGallery} onClick={() => { imageGallery.state.isFullscreen ? imageGallery.exitFullScreen() : imageGallery.fullScreen() }} items={imgs} showThumbnails={false} useBrowserFullscreen={false} showPlayButton={false} showFullscreenButton={false} showBullets />
					</div>
				</div>
			)
		}

		const href = i18n.t(`experiences.companies.${company}.href`);

		return (
			<TabPane tabId={`pills${key}`} key={key}>
				<div className={'company'}>
					<h2 className={'second-color'}>
						{href
							? <a
								href={href}
								rel={'noopener noreferrer'}
								target={'_blank'}
								className={'second-color'}
							>
								{i18n.t(`experiences.companies.${company}.label`)}
							</a>
							: i18n.t(`experiences.companies.${company}.label`)}
					</h2>
					<p>{i18n.t(`experiences.companies.${company}.description`)}</p>
				</div>
				<div className={'missions ml-auto mr-auto'}>
					<Row className={'position-date'}>
						<h3 className={'third-color'}>
							{i18n.t(`experiences.companies.${company}.position`)}
						</h3>
						<h5 className={'date'}>
							{i18n.t(`experiences.companies.${company}.date`)}
						</h5>
					</Row>
					<div className={'col-md-12 ml-auto mr-auto'}>
						<div>
							<p>{i18n.t(`experiences.companies.${company}.missions.description`)}</p>
							{i18n.t(`experiences.companies.${company}.missions.more`).split('\n').map((text, index) =>
								<div key={`more-${index}`} className={'experiences-details'}><br />{text}</div>
							)}
						</div>
					</div>
					<div className={'technologies ml-auto mr-auto field is-grouped is-grouped-multiline'}>
						<Row className={'col-md-12 fourth-color'}>
							<h3>
								{i18n.t(`experiences.companies.${company}.technologies.label`)}
							</h3>
						</Row>
						<div className={'col-md-12 ml-auto mr-auto field is-grouped is-grouped-multiline'}>
							{i18n.t(`experiences.companies.${company}.technologies.technos`).split('\n').map((text, index) =>
								<div className={'control'} key={`technologies-${index}`}>
									<div className={'tags has-addons'}>
										{text.split(':').map((tag, index, array) =>
											<span
												className={`tag ${index === 0 && array.length > 1 ? 'is-dark' : 'is-info'}`}
												key={`tags-${index}`}
											>
												{tag}
											</span>
										)}
									</div>
								</div>
							)}
						</div>
					</div>
					{renderPortfolioImgs()}
				</div>
			</TabPane>
		)
	}

	return (
		<div className={'col-md-10 ml-auto mr-auto'}>
			<Row className={'ml-auto mr-auto experiences'}>
				<TabContent activeTab={'pills' + props.pills}>
					{props.companies.map((company, index) =>
						renderTabPane(company, index)
					)}
				</TabContent>
			</Row>
		</div>
	)
}

export default Experiences;
