import React from 'react'

import HeaderRow from 'src/components/Headers/HeaderNavBar'
import HeaderSection from 'src/components/Headers/HeaderSection'
import { ToastContainer } from 'react-toastify'
import { Switch } from 'react-router'

function BasePage (props) {
	const Header = props.header;
	const MainComponent = props.mainComponent;

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, [props.mainComponent.name]);

	return (
		<>
			<HeaderRow languageChanged={props.languageChanged} lang={props.lang} theme={props.theme} setTheme={props.setTheme} />
			<div className={'wrapper ' + props.className}>
				<HeaderSection>
					<Header pills={props.pills} setPills={props.setPills} companies={props.companies} type={props.type} />
				</HeaderSection>
				<div id={'data-theme'} data-theme={props.theme} className={'section body'}>
					{props.pills === undefined
						? <MainComponent companies={props.companies} pills={props.pills} theme={props.theme} lang={props.lang} />
						: props.pills !== -1
							? <MainComponent companies={props.companies} pills={props.pills} theme={props.theme} lang={props.lang} />
							: <Header pills={props.pills} type={props.type} setPills={props.setPills} companies={props.companies} onlyPill={true} />
					}
				</div>
				{/* <DarkFooter /> */}
			</div>
			<ToastContainer autoClose={8000}/>
		</>
	)
}

export default BasePage
