import React from 'react'

import {
	Collapse,
	Container,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Nav,
	Navbar, NavbarBrand,
	NavItem,
	UncontrolledDropdown
} from 'reactstrap'
import i18n from 'src/i18n'
import { NavLink } from 'react-router-dom'

function HeaderNavbar (props) {
	let navbar;

	const [classNames, setClassNames] = React.useState('absolute navbar-transparent');
	const [collapseOpen, setCollapseOpen] = React.useState(false);

	React.useEffect(() => {
		let lastScrollTop = window.pageYOffset;
		const updateNavbarColor = () => {
			let scrollTop = window.pageYOffset;
			const navHeight = navbar ? navbar.clientTop + navbar.clientHeight : 100;
			if(scrollTop <= 0)
				setClassNames('absolute navbar-transparent');
			else if(scrollTop > navHeight) {
				if(scrollTop <= lastScrollTop && window.innerHeight + scrollTop < document.body.offsetHeight)
					setClassNames('fixed first-color-background fade-in-out');
				else if(lastScrollTop > navHeight)
					setClassNames('away navbar-transparent fade-in-out');
				else
					if(classNames.indexOf('fixed') !== -1)
						setClassNames('away navbar-transparent fade-in-out');
					else
						setClassNames('away navbar-transparent');
			} else
				if(lastScrollTop > scrollTop)
					setClassNames('fixed navbar-transparent fade-in-out');
				else if(scrollTop > navHeight && lastScrollTop < navHeight)
					setClassNames('fixed navbar-transparent fade-in-out');
				// else if(lastScrollTop < navHeight && lastScrollTop < scrollTop)
				// 	setClassNames('away navbar-transparent fade-in-out');
				else if(scrollTop < navHeight && lastScrollTop > navHeight)
					setClassNames('absolute navbar-transparent fade-in-out');

			lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
		};

		window.addEventListener('scroll', updateNavbarColor);
		return function cleanup () {
			window.removeEventListener('scroll', updateNavbarColor);
		};
	});

	const toogleNavBar = () => {
		document.documentElement.classList.toggle('nav-open');
		setCollapseOpen(!collapseOpen);
	}

	return (
		<>
			{collapseOpen ? (
				<div
					id={'bodyClick'}
					onClick={() => {
						document.documentElement.classList.toggle('nav-open');
						setCollapseOpen(false);
					}}
				/>
			) : null}
			<Navbar className={classNames} expand={'lg'}>
				<Container className={'col-md-12'}>
					<div className={'navbar-translate'} ref={nav => navbar = nav}>
						<NavbarBrand>
							<img className={(typeof window === "undefined" || window.location.pathname === '/') && classNames.indexOf('navbar-transparent') !== -1 ? 'logoRainntech no-display' : 'logoRainntech'}
								alt={'logo RAInnTech'}
								src={'/logo-no-motto.svg'}
							/>
						</NavbarBrand>
						<button
							className={'navbar-toggler navbar-toggler'}
							onClick={toogleNavBar}
							aria-expanded={collapseOpen}
							type={'button'}
							aria-label={'Expand menu'}
						>
							<span className={'navbar-toggler-bar top-bar'} />
							<span className={'navbar-toggler-bar middle-bar'} />
							<span className={'navbar-toggler-bar bottom-bar'} />
						</button>
					</div>
					<Collapse
						className={'justify-content-end'}
						isOpen={collapseOpen}
						navbar
					>
						<div className={'theme-dropdown'}>
							<UncontrolledDropdown className={'dropdownLang'}>
								<DropdownToggle
									aria-expanded={false}
									aria-haspopup
									caret
									color={'secondary'}
									data-toggle={'dropdown'}
									id={'dropdownMenuButton'}
									type={'button'}
								>
									<img className={'flags'} src={`/flags/${props.lang}.png`} alt={`${props.lang} flag`} /> {i18n.t(`lang.${props.lang}`)}
								</DropdownToggle>
								<DropdownMenu aria-labelledby={'dropdownMenuButton'}>
									{i18n.languages.map((_lang) => (
										<DropdownItem key={_lang} onClick={() => props.languageChanged(_lang)}>
											<img className={'flags'} src={`/flags/${_lang}.png`} alt={`${_lang} flag`} /> {i18n.t(`lang.${_lang}`)}
										</DropdownItem>
									))}
								</DropdownMenu>
							</UncontrolledDropdown>
							<i className={`fa${props.theme === true ? 'r' : 's'} fa-lightbulb theme`} onClick={() => props.setTheme(!props.theme)} />
						</div>
						<Nav navbar>
							<NavItem>
								<NavLink onClick={collapseOpen ? toogleNavBar : null} className={'path'} activeClassName={'active'} exact to={'/'}>{i18n.t('paths.home')}</NavLink>
							</NavItem>
							<NavItem>
								<NavLink onClick={collapseOpen ? toogleNavBar : null} className={'path'} activeClassName={'active'} to={'/about'}>{i18n.t('paths.about')}</NavLink>
							</NavItem>
							<NavItem>
								<NavLink onClick={collapseOpen ? toogleNavBar : null} className={'path'} activeClassName={'active'} to={'/skills'}>{i18n.t('paths.skills')}</NavLink>
							</NavItem>
							<NavItem>
								<NavLink onClick={collapseOpen ? toogleNavBar : null} className={'path'} activeClassName={'active'} to={'/experiences'}>{i18n.t('paths.experiences')}</NavLink>
							</NavItem>
							<NavItem>
								<NavLink onClick={collapseOpen ? toogleNavBar : null} className={'path'} activeClassName={'active'} to={'/educations'}>{i18n.t('paths.educations')}</NavLink>
							</NavItem>
							<NavItem>
								<NavLink onClick={collapseOpen ? toogleNavBar : null} className={'path'} activeClassName={'active'} to={'/contact'}>{i18n.t('paths.contact')}</NavLink>
							</NavItem>
						</Nav>
					</Collapse>
				</Container>
			</Navbar>
		</>
	);
}

export default HeaderNavbar
