import React from 'react'

import { Container } from 'reactstrap'

function HeaderSection (props) {
	return (
		<>
			<div
				className={'page-header clear-filter page-header-small'}
				filter-color={'blue'}
			>
				<div
					className={'page-header-image'}
				/>
				<Container>
					{props.children}
				</Container>
			</div>
		</>
	)
}

export default HeaderSection
