import React from 'react'
import { Container, Row } from 'reactstrap'
import i18n from 'src/i18n'
import { Helmet } from 'react-helmet'

function Home() {
	const renderServices = (type) => {
		const services = Object.keys(i18n.getResource(i18n.language, 'translation', 'home.services'));

		return services.map((type, key) =>
			<div className={'col-md-4 services'} key={`services-${key}`}>
				<div className={'service col-md-12'}>
					<h5>{i18n.t(`home.services.${type}.label`)}</h5>
					<hr/>
					{i18n.t(`home.services.${type}.description`).split('\n').map((desc, keyDescription) =>
						<p key={`services-description-${keyDescription}`}>
							{desc}
						</p>
					)}
				</div>
			</div>
		)
	};

	return (
		<Container className={'about-content'}>
			<Helmet defaultTitle={"RAInnTech"}>
				<html lang={i18n.language} />
				<meta name="description" content={i18n.t('home.description')} lang={i18n.language} />
				<title itemProp="name" lang={i18n.language}>RAInnTech</title>  {/*- ${i18n.t('home.label')}*/}
			</Helmet>
			<Row className={'col-md-12 row-container'}>
				<h3 className={'title second-color'}>{i18n.t('home.goal')}</h3>
				<h5 className={'description col-md-12'} dangerouslySetInnerHTML={
					{__html: i18n.t('home.presentation.main', {interpolation: {escapeValue: false}})}
				} />
				<h5 className={'description align col-md-12'} dangerouslySetInnerHTML={
					{__html: i18n.t('home.presentation.more', {interpolation: {escapeValue: false}})}
				} />
			</Row>
			<Row className={'col-md-12 row-container'}>
				<h3 className={'title second-color'}>{i18n.t('home.label')}</h3>
				<Row>
					{renderServices()}
				</Row>
			</Row>
		</Container>
	)
}

export default Home;
