import React from 'react'
import i18n from 'src/i18n'

function HeaderProfile (props) {
	// const getAge = () => {
	// 	const born = new Date(1995, 7, 1)
	// 	return Math.abs(Math.round((Date.now() - born.getTime()) / 1000 / (60 * 60 * 24) / 365.25))
	// }

	return (
		<>
			<div className={'photo-container'}>
				<img alt={'Alexandre_ROBIN'} src={'/alex.jpeg'} />
			</div>
			<a href={'mailto:contact@RAinntech.com'} className={'mail-to'}>
				<h3 className={'title'}>Alexandre ROBIN</h3>
			</a>
			<p className={'category'}><span className={'category-capitalize'}>{i18n.t('job.label')}</span></p> {/*{', '}{i18n.t('yearsOld', { age: getAge() })}*/}
		</>
	)
}

export default HeaderProfile
