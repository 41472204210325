import ReactDOM from 'react-dom'

import 'src/assets/css/bootstrap.min.css'
import 'bulma'
import 'src/assets/css/app.css'
import 'src/assets/css/fontawesome.min.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-image-gallery/styles/css/image-gallery.css'

import App from 'src/App'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'

/**
 * @return {null}
 */
ReactDOM.hydrate(
	<BrowserRouter>
		<App {...window.__STATE__}/>
	</BrowserRouter>, document.getElementById('root')
)
