import React from 'react'
import { div, Container, Row, UncontrolledTooltip } from 'reactstrap'
import i18n from 'src/i18n'
import { Helmet } from 'react-helmet'

function Skills () {
	return (
		<Container>
			<Helmet defaultTitle={"RAInnTech"}>
				<html lang={i18n.language} />
				<meta name="description" content={i18n.t('skills.description')} lang={i18n.language} />
				<title itemProp="name" lang={i18n.language}>RAInnTech - {i18n.t('paths.skills')}</title>
			</Helmet>
			<Row>
				<Row className={'col-md-12 technos-section'}>
					<h3 className={'third-color col-md-12'}>
						{i18n.t('skills.languages')}
					</h3>
					<div className={'col-lg-2 col-md-4 col-sm-4 col-6 ml-auto mr-auto'} id={'tooltip-javascript'}>
						<div className={'logo-techno heighty col-md-12'}>
							<i className={'col-md-12'}>
								<img alt={'Javascript logo'} src={`/technos/js.png`}/>
							</i>
							Javascript
						</div>
						<UncontrolledTooltip delay={0} target={'tooltip-javascript'}>
							80%
						</UncontrolledTooltip>
					</div>
					<div className={'col-lg-2 col-md-4 col-sm-4 col-6 ml-auto mr-auto'} id={'tooltip-typescript'}>
						<div className={'logo-techno heighty col-md-12'}>
							<i className={'col-md-12'}>
								<img alt={'Typescript logo'} src={`/technos/ts.png`}/>
							</i>
							Typescript
						</div>
						<UncontrolledTooltip delay={0} target={'tooltip-typescript'}>
							80%
						</UncontrolledTooltip>
					</div>
					<div className={'col-lg-2 col-md-4 col-sm-4 col-6 ml-auto mr-auto'} id={'tooltip-python'}>
						<div className={'logo-techno seventyfive col-md-12'}>
							<i className={'col-md-12'}>
								<img alt={'Python logo'} src={`/technos/python.png`}/>
							</i>
							Python
						</div>
						<UncontrolledTooltip delay={0} target={'tooltip-python'}>
							75%
						</UncontrolledTooltip>
					</div>
					<div className={'col-lg-2 col-md-4 col-sm-4 col-6 ml-auto mr-auto'} id={'tooltip-java'}>
						<div className={'logo-techno seventyfive col-md-12'}>
							<i className={'col-md-12'}>
								<img alt={'Java logo'} src={`/technos/java.png`}/>
							</i>
							Java
						</div>
						<UncontrolledTooltip delay={0} target={'tooltip-java'}>
							75%
						</UncontrolledTooltip>
					</div>
					<div className={'col-lg-2 col-md-4 col-sm-4 col-6 ml-auto mr-auto'} id={'tooltip-php'}>
						<div className={'logo-techno fifty col-md-12'}>
							<i className={'col-md-12'}>
								<img alt={'php logo'} src={`/technos/php.png`}/>
							</i>
							php
						</div>
						<UncontrolledTooltip delay={0} target={'tooltip-php'}>
							50%
						</UncontrolledTooltip>
					</div>
					<div className={'col-lg-2 col-md-4 col-sm-4 col-6 ml-auto mr-auto'} id={'tooltip-cplus'}>
						<div className={'logo-techno forty col-md-12'}>
							<i className={'col-md-12'}>
								<img alt={'C++ logo'} src={`/technos/cplus.png`}/>
							</i>
							C++
						</div>
						<UncontrolledTooltip delay={0} target={'tooltip-cplus'}>
							40%
						</UncontrolledTooltip>
					</div>
					<div className={'col-lg-2 col-md-4 col-sm-4 col-6 ml-auto mr-auto'} id={'tooltip-html'}>
						<div className={'logo-techno ninety col-md-12'}>
							<i className={'col-md-12'}>
								<img alt={'HTML logo'} src={`/technos/html.png`}/>
							</i>
							HTML
						</div>
						<UncontrolledTooltip delay={0} target={'tooltip-html'}>
							90%
						</UncontrolledTooltip>
					</div>
					<div className={'col-lg-2 col-md-4 col-sm-4 col-6 ml-auto mr-auto'} id={'tooltip-css'}>
						<div className={'logo-techno ninety col-md-12'}>
							<i className={'col-md-12'}>
								<img alt={'CSS logo'} src={`/technos/css.png`}/>
							</i>
							CSS
						</div>
						<UncontrolledTooltip delay={0} target={'tooltip-css'}>
							90%
						</UncontrolledTooltip>
					</div>
					<div className={'col-lg-2 col-md-4 col-sm-4 col-6 ml-auto mr-auto'} id={'tooltip-sass'}>
						<div className={'logo-techno sixty col-md-12'}>
							<i className={'col-md-12'}>
								<img alt={'SASS logo'} src={`/technos/scss.png`}/>
							</i>
							SASS
						</div>
						<UncontrolledTooltip delay={0} target={'tooltip-sass'}>
							60%
						</UncontrolledTooltip>
					</div>
				</Row>

				<Row className={'col-md-12 technos-section'}>
					<h3 className={'third-color col-md-12'}>
						{i18n.t('skills.environments')}
					</h3>
					<div className={'col-lg-2 col-md-4 col-sm-4 col-6 ml-auto mr-auto'} id={'tooltip-node'}>
						<div className={'logo-techno heighty col-md-12'}>
							<i className={'col-md-12'}>
								<img alt={'Node.JS logo'} src={`/technos/node.png`}/>
							</i>
							Node.JS
						</div>
						<UncontrolledTooltip delay={0} target={'tooltip-node'}>
							80%
						</UncontrolledTooltip>
					</div>
				</Row>

				<Row className={'col-md-12 technos-section'}>
					<h3 className={'third-color col-md-12'}>
						{i18n.t('skills.frameworks')}
					</h3>
					<div className={'col-lg-2 col-md-4 col-sm-4 col-6 ml-auto mr-auto'} id={'tooltip-reactjs'}>
						<div className={'logo-techno heightyfive col-md-12'}>
							<i className={'col-md-12'}>
								<img alt={'React.JS logo'} src={`/technos/reactjs.png`}/>
							</i>
							React.JS
						</div>
						<UncontrolledTooltip delay={0} target={'tooltip-reactjs'}>
							85%
						</UncontrolledTooltip>
					</div>
					<div className={'col-lg-2 col-md-4 col-sm-4 col-6 ml-auto mr-auto'} id={'tooltip-reactnative'}>
						<div className={'logo-techno seventy col-md-12'}>
							<i className={'col-md-12'}>
								<img alt={'React Native logo'} src={`/technos/reactjs.png`}/>
							</i>
							React Native
						</div>
						<UncontrolledTooltip delay={0} target={'tooltip-reactnative'}>
							70%
						</UncontrolledTooltip>
					</div>
					<div className={'col-lg-2 col-md-4 col-sm-4 col-6 ml-auto mr-auto'} id={'tooltip-bootstrap'}>
						<div className={'logo-techno seventyfive col-md-12'}>
							<i className={'col-md-12'}>
								<img alt={'Boostrap logo'} src={`/technos/bootstrap.png`}/>
							</i>
							Boostrap
						</div>
						<UncontrolledTooltip delay={0} target={'tooltip-bootstrap'}>
							75%
						</UncontrolledTooltip>
					</div>
					{/*Scrapping*/}
					<div className={'col-lg-2 col-md-4 col-sm-4 col-6 ml-auto mr-auto'} id={'tooltip-scrapy'}>
						<div className={'logo-techno seventy col-md-12'}>
							<i className={'fas fa-robot col-md-12'}/>
							Scraping / Scrapy
						</div>
						<UncontrolledTooltip delay={0} target={'tooltip-scrapy'}>
							70%
						</UncontrolledTooltip>
					</div>
				</Row>

				<Row className={'col-md-12 technos-section'}>
					<h3 className={'third-color col-md-12'}>
						{i18n.t('skills.db')}
					</h3>
					<div className={'col-lg-2 col-md-4 col-sm-4 col-6 ml-auto mr-auto'} id={'tooltip-mysql'}>
						<div className={'logo-techno heightyfive col-md-12'}>
							<i className={'col-md-12'}>
								<img alt={'MySQL logo'} src={`/technos/mysql.png`}/>
							</i>
							MySQL
						</div>
						<UncontrolledTooltip delay={0} target={'tooltip-mysql'}>
							85%
						</UncontrolledTooltip>
					</div>
					<div className={'col-lg-2 col-md-4 col-sm-4 col-6 ml-auto mr-auto'} id={'tooltip-mongo'}>
						<div className={'logo-techno heighty col-md-12'}>
							<i className={'col-md-12'}>
								<img alt={'MongoDB logo'} src={`/technos/mongo.png`}/>
							</i>
							MongoDB
						</div>
						<UncontrolledTooltip delay={0} target={'tooltip-mongo'}>
							80%
						</UncontrolledTooltip>
					</div>
					<div className={'col-lg-2 col-md-4 col-sm-4 col-6 ml-auto mr-auto'} id={'tooltip-postgre'}>
						<div className={'logo-techno sixty col-md-12'}>
							<i className={'col-md-12'}>
								<img alt={'PostgreSQL logo'} src={`/technos/postgre.png`}/>
							</i>
							PostgreSQL
						</div>
						<UncontrolledTooltip delay={0} target={'tooltip-postgre'}>
							60%
						</UncontrolledTooltip>
					</div>
				</Row>

				<Row className={'col-md-12 technos-section'}>
					<h3 className={'third-color col-md-12'}>
						{i18n.t('skills.library')}
					</h3>
					<div className={'col-lg-2 col-md-4 col-sm-4 col-6 ml-auto mr-auto'} id={'tooltip-tf'}>
						<div className={'logo-techno thirtyfive col-md-12'}>
							<i className={'col-md-12'}>
								<img alt={'TensorFlow logo'} src={`/technos/tf.png`}/>
							</i>
							TensorFlow
						</div>
						<UncontrolledTooltip delay={0} target={'tooltip-tf'}>
							35%
						</UncontrolledTooltip>
					</div>
					<div className={'col-lg-2 col-md-4 col-sm-4 col-6 ml-auto mr-auto'} id={'tooltip-pytorch'}>
						<div className={'logo-techno thirtyfive col-md-12'}>
							<i className={'col-md-12'}>
								<img alt={'PyTorch logo'} src={`/technos/pytorch.png`}/>
							</i>
							PyTorch
						</div>
						<UncontrolledTooltip delay={0} target={'tooltip-pytorch'}>
							35%
						</UncontrolledTooltip>
					</div>
				</Row>

				<Row className={'col-md-12 technos-section'}>
					<h3 className={'third-color col-md-12'}>
						{i18n.t('skills.searchEngine')}
					</h3>
					<div className={'col-lg-2 col-md-4 col-sm-4 col-6 ml-auto mr-auto'} id={'tooltip-es'}>
						<div className={'logo-techno seventy col-md-12'}>
							<i className={'col-md-12'}>
								<img alt={'Elastic Search logo'} src={`/technos/es.png`}/>
							</i>
							Elastic Search
						</div>
						<UncontrolledTooltip delay={0} target={'tooltip-es'}>
							70%
						</UncontrolledTooltip>
					</div>
				</Row>

				<Row className={'col-md-12 technos-section'}>
					<h3 className={'third-color col-md-12'}>
						{i18n.t('skills.nlp')}
					</h3>
					<div className={'col-lg-2 col-md-4 col-sm-4 col-6 ml-auto mr-auto'} id={'tooltip-df'}>
						<div className={'logo-techno heighty col-md-12'}>
							<i className={'col-md-12'}>
								<img alt={'Dialogflow logo'} src={'/technos/dg.svg'}/>
							</i>
							Dialogflow
						</div>
						<UncontrolledTooltip delay={0} target={'tooltip-df'}>
							80%
						</UncontrolledTooltip>
					</div>
					<div className={'col-lg-2 col-md-4 col-sm-4 col-6 ml-auto mr-auto'} id={'tooltip-flair'}>
						<div className={'logo-techno fifty col-md-12'}>
							<i className={'col-md-12'}>
								<img alt={'Flair logo'} src={'/technos/flair.svg'}/>
							</i>
							Flair
						</div>
						<UncontrolledTooltip delay={0} target={'tooltip-flair'}>
							50%
						</UncontrolledTooltip>
					</div>
					<div className={'col-lg-2 col-md-4 col-sm-4 col-6 ml-auto mr-auto'} id={'tooltip-w2v'}>
						<div className={'logo-techno fifty col-md-12'}>
							<i className={'fas fa-bezier-curve col-md-12'}/>
							Word2Vec
						</div>
						<UncontrolledTooltip delay={0} target={'tooltip-w2v'}>
							50%
						</UncontrolledTooltip>
					</div>
				</Row>

				<Row className={'col-md-12 technos-section'}>
					<h3 className={'third-color col-md-12'}>
						{i18n.t('skills.dvcs')}
					</h3>
					<div className={'col-lg-2 col-md-4 col-sm-4 col-6 ml-auto mr-auto'} id={'tooltip-git'}>
						<div className={'logo-techno ninety col-md-12'}>
							<i className={'col-md-12'}>
								<img alt={'GitHub logo'} src={`/technos/git.png`}/>
							</i>
							GitHub
						</div>
						<UncontrolledTooltip delay={0} target={'tooltip-git'}>
							90%
						</UncontrolledTooltip>
					</div>
				</Row>

				<Row className={'col-md-12 technos-section'}>
					<h3 className={'third-color col-md-12'}>
						{i18n.t('skills.platform')}
					</h3>
					<div className={'col-lg-2 col-md-4 col-sm-4 col-6 ml-auto mr-auto'} id={'tooltip-docker'}>
						<div className={'logo-techno heighty col-md-12'}>
							<i className={'col-md-12'}>
								<img alt={'Docker logo'} src={`/technos/docker.png`}/>
							</i>
							Docker
						</div>
						<UncontrolledTooltip delay={0} target={'tooltip-docker'}>
							80%
						</UncontrolledTooltip>
					</div>
				</Row>

				<Row className={'col-md-12 technos-section'}>
					<h3 className={'third-color col-md-12'}>
						{i18n.t('skills.os')}
					</h3>
					<div className={'col-lg-2 col-md-4 col-sm-4 col-6 ml-auto mr-auto'} id={'tooltip-mac'}>
						<div className={'logo-techno ninetyfive col-md-12'}>
							<i className={'col-md-12'}>
								<img alt={'MacOS logo'} src={`/technos/mac.png`}/>
							</i>
							MacOS
						</div>
						<UncontrolledTooltip delay={0} target={'tooltip-mac'}>
							95%
						</UncontrolledTooltip>
					</div>
					<div className={'col-lg-2 col-md-4 col-sm-4 col-6 ml-auto mr-auto'} id={'tooltip-linux'}>
						<div className={'logo-techno heighty col-md-12'}>
							<i className={'col-md-12'}>
								<img alt={'Linux logo'} src={`/technos/linux.png`}/>
							</i>
							Linux
						</div>
						<UncontrolledTooltip delay={0} target={'tooltip-linux'}>
							80%
						</UncontrolledTooltip>
					</div>
					<div className={'col-lg-2 col-md-4 col-sm-4 col-6 ml-auto mr-auto'} id={'tooltip-windows'}>
						<div className={'logo-techno seventyfive col-md-12'}>
							<i className={'col-md-12'}>
								<img alt={'Windows logo'} src={`/technos/windows.png`}/>
							</i>
							Windows
						</div>
						<UncontrolledTooltip delay={0} target={'tooltip-windows'}>
							75%
						</UncontrolledTooltip>
					</div>
				</Row>
			</Row>
		</Container>
	)
}

export default Skills
