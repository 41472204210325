import i18next from 'i18next/index'
import translation from 'src/translations.json'

const langs = Object.keys(translation)

i18next.init({
	interpolation: {
		escapeValue: false
	},
	lng: langs[0],
	languages: langs,
	fallbackLng: langs,
	whitelist: langs,
	resources: translation
})

export default i18next
