import React from 'react'
import { Button, Container, UncontrolledTooltip } from 'reactstrap'
import i18n from 'src/i18n'
import { Helmet } from 'react-helmet'

export default function About () {
	const goTo = (e, url, blank = true) => {
		e.preventDefault()
		window.open(url, blank ? '_blank' : '_parent')
	}

	return (
		<Container>
			<Helmet defaultTitle={"RAInnTech"}>
				<html lang={i18n.language} />
				<meta name="description" content={i18n.t('description.1')} lang={i18n.language} />
				<title itemProp="name" lang={i18n.language}>RAInnTech - {i18n.t('paths.about')}</title>
			</Helmet>
			<div className={'button-container'}>
				<Button
					className={'btn-round btn-icon btn-about'}
					color={'info'}
					id={'linkedin'}
					size={'lg'}
					onClick={(e) => goTo(e, 'https://www.linkedin.com/in/alexandre-robin-06848383')}
				>
					<i className={'fab fa-linkedin'} />
				</Button>
				<UncontrolledTooltip delay={0} target={'linkedin'}>
					{i18n.t('linkedin')}
				</UncontrolledTooltip>
				<Button
					className={'btn-round btn-icon btn-about'}
					color={'warning'}
					id={'git'}
					size={'lg'}
					onClick={(e) => goTo(e, 'https://github.com/robinalexandre')}
				>
					<i className={'fab fa-github'} />
				</Button>
				<UncontrolledTooltip delay={0} target={'git'}>
					{i18n.t('git')}
				</UncontrolledTooltip>
				<Button
					className={'btn-round btn-icon btn-about'}
					color={'danger'}
					id={'resume'}
					size={'lg'}
					onClick={(e) => goTo(e, "/Alexandre's Resume.pdf")}
				>
					<i className={'fas fa-file-pdf'} />
				</Button>
				<UncontrolledTooltip delay={0} target={'resume'}>
					{i18n.t('resume')}
				</UncontrolledTooltip>
				<Button
					className={'btn-round btn-icon btn-about'}
					color={'primary'}
					id={'mail'}
					size={'lg'}
					onClick={(e) => goTo(e, 'mailto:contact@RAinntech.com', false)}
				>
					<i className={'fas fa-envelope'} />
				</Button>
				<UncontrolledTooltip delay={0} target={'mail'}>
					{i18n.t('mail')}
				</UncontrolledTooltip>
				<Button
					className={'btn-round btn-icon btn-about'}
					color={'success'}
					id={'tel'}
					size={'lg'}
					onClick={(e) => goTo(e, 'tel:+33660094574', false)}
				>
					<i className={'fas fa-phone'} />
				</Button>
				<UncontrolledTooltip delay={0} target={'tel'}>
					{i18n.t('tel')}
				</UncontrolledTooltip>
			</div>
			<div className={'about-content'}>
				<h3 className={'title second-color'}>{i18n.t('aboutMe')}</h3>
				<h5 className={'description'}>
					{i18n.t('description.1')}
					<br /><br />
					{i18n.t('description.2')}
					<br /><br />
					{i18n.t('description.3')}
					<br /><br />
					{i18n.t('description.4')}
				</h5>
			</div>
		</Container>
	)
}
