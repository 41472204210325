import React from 'react'
import i18n from 'src/i18n'
import { Col, Nav, NavItem, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

function HeaderBasePage (props) {
	const renderNavItem = (company, key) => {
		// const getLabel = () => {
		// 	return props.type === 'educations' ? i18n.t(`educations.schools.${company}.label`) : i18n.t(`experiences.companies.${company}.label`);
		// }

		let description;
		let title;
		let label;
		if(props.type === 'educations') {
			description = `educations.schools.${company}.speciality`;
			title = `educations.schools.${company}.label`;
			label = i18n.t(`educations.schools.${company}.label`);
		} else {
			description = `experiences.companies.${company}.missions.description`;
			title = `experiences.companies.${company}.label`;
			label = i18n.t(`experiences.companies.${company}.label`);
		}

		return (
			<NavItem key={`${key}${props.type}`} className={props.pills === -1 ? `col-md-${2 * Math.round(12 / props.companies.length / 2)}` : ''}>
				{props.pills !== -1 ?
					props.pills === key ?
						<Helmet defaultTitle={"RAInnTech"}>
							<html lang={i18n.language} />
							<meta name="description" content={i18n.t(description)} lang={i18n.language} />
							<title itemProp="name" lang={i18n.language}>RAInnTech - {i18n.t(`paths.${props.type}`)} - {i18n.t(title)}</title>
						</Helmet>
					: null
				:
					<Helmet defaultTitle={"RAInnTech"}>
						<html lang={i18n.language} />
						<meta name="description" content={i18n.t(`${props.type}.description`)} lang={i18n.language} />
						<title itemProp="name" lang={i18n.language}>RAInnTech - {i18n.t(`paths.${props.type}`)}</title>
					</Helmet>
				}
				<div className={'navlink-pills'}>
					<Link
						className={props.pills === key ? 'active nav-logo nav-link' : 'nav-logo nav-link '}
						to={`/${props.type}/${company}`}
						onClick={_ => props.setPills(key)}>
						<img
							className={'logo'}
							src={`/logo/logo_${company}.png`}
							alt={'logo'}
						/>
					</Link>
					{props.onlyPill ? <div className={'label-pills row'}>{label}</div> : null}
				</div>
			</NavItem>
		)
	}

	const renderTitle = () => {
		return (
			<h2 className={'title text-center'}>
				{i18n.t(`${props.type}.label`)}
			</h2>
		)
	}

	const renderPills = () => {
		return (
			<div className={'nav-align-center'}>
				<Nav className={'nav-pills-info nav-pills-just-icons table-logo'} pills role={'tablist'}>
					{props.companies.map((company, index) =>
						renderNavItem(company, index)
					)}
				</Nav>
			</div>
		)
	}

	const render = () => {
		if (props.pills !== undefined && props.pills !== -1) {
			return (
				<>
					{renderTitle()}
					{renderPills()}
				</>)
		} else if(props.onlyPill) {
			return renderPills()
		} else {
			return renderTitle()
		}
	}

	return (
		<Row className={'col-md-12 ml-auto mr-auto'}>
			<Col className={'ml-auto mr-auto'}>
				{render()}
			</Col>
		</Row>
	)
}

export default HeaderBasePage
