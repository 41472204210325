import React from 'react'
import { Button, Container, Form, FormGroup, Input, Row } from 'reactstrap'
import i18n from 'src/i18n'
import axios from 'axios'
import { toast } from 'react-toastify'
import Reaptcha from 'reaptcha';

import { Helmet } from 'react-helmet'

function Contact(props) {
	let nameRef;
	let emailRef;
	let subjectRef;
	let descriptionRef;
	let recaptchaRef;

	const [name, setName] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [phone, setPhone] = React.useState('');
	const [subject, setSubject] = React.useState('');
	const [description, setDescription] = React.useState('');
	const [budget, setBudget] = React.useState('');
	const [isLoading, setIsLoading] = React.useState(false);
	const [token, setToken] = React.useState('');
	const [captchaReady, setCaptchaReady] = React.useState(false);

	React.useEffect(() => {
		if(captchaReady)
			recaptchaRef.reset();
	}, [props.lang, props.theme]);

	const handleSubmit = (e) => {
		if(e) e.preventDefault();

		if(!name)
			nameRef.focus();
		else if(!email)
			emailRef.focus();
		else if(!subject)
			subjectRef.focus();
		else if(!description)
			descriptionRef.focus();
		else if(!token) {
			toast.warn(i18n.t('contact.noCaptcha'), {
				position: toast.POSITION.BOTTOM_RIGHT
			});
		} else {
			setIsLoading(true);
			axios.post('/sendMail', {
				name: name,
				email: email,
				phone: phone,
				subject: subject,
				description: description,
				budget: budget,
				captcha: token,
				lang: props.lang
			}, {
				headers: {
					'Content-Type': 'application/json'
				}
			}).then((result) => {
				setIsLoading(false);
				if (result.status === 200) {
					toast.success(i18n.t('contact.sent'), {
						position: toast.POSITION.BOTTOM_RIGHT
					});
				} else if(result.status === 500) {
					console.error(result.data.error);
					toast.error(i18n.t('contact.error'), {
						position: toast.POSITION.BOTTOM_RIGHT
					});
				} else {
					console.error(result.data.error);
					recaptchaRef.reset();
					toast.error(i18n.t('contact.captcha'), {
						position: toast.POSITION.BOTTOM_RIGHT
					});
				}
			}).catch((e) => {
				setIsLoading(false);
				console.warn(e);
				toast.error(i18n.t('contact.error'), {
					position: toast.POSITION.BOTTOM_RIGHT
				});
			});
		}
	}

	return (
		<Container className={'contact'}>
			<Helmet defaultTitle={"RAInnTech"}>
				<html lang={i18n.language} />
				<meta name="description" content={i18n.t('contact.description')} lang={i18n.language} />
				<title itemProp="name" lang={i18n.language}>RAInnTech - {i18n.t('paths.contact')}</title>
			</Helmet>
			<h4 className={'contact-title'}>{i18n.t('contact.fill')}</h4>
			<Form>
				<FormGroup>
					<Input placeholder={i18n.t('contact.fields.name')} type={'text'} onChange={e => setName(e.currentTarget.value)} innerRef={(r) => nameRef = r} />
				</FormGroup>
				<Row>
					<div className="col-md-6 col-contact">
						<Input placeholder={i18n.t('contact.fields.email')} type={'email'} onChange={e => setEmail(e.currentTarget.value)} className={'form-control-lg'} innerRef={(r) => emailRef = r} />
					</div>
					<div className="col-md-6 col-contact">
						<Input placeholder={i18n.t('contact.fields.phone')} type={'phone'} onChange={e => setPhone(e.currentTarget.value)} className={'form-control-lg'} />
					</div>
				</Row>
				<br/>
				<FormGroup>
					<Input type={'text'} placeholder={i18n.t('contact.fields.subject')} onChange={e => setSubject(e.currentTarget.value)} innerRef={(r) => subjectRef = r} />
				</FormGroup>
				<FormGroup>
					<textarea className={'form-control'} placeholder={i18n.t('contact.fields.description')} onChange={e => setDescription(e.currentTarget.value)} ref={(r) => descriptionRef = r} />
				</FormGroup>
				<FormGroup>
					<Input type={'text'} placeholder={i18n.t('contact.fields.budget')} onChange={e => setBudget(e.currentTarget.value)} />
				</FormGroup>
				<Reaptcha
					sitekey={'6Ld9BswUAAAAAGHXztG7riOaTUwkEC44RM1RriNN'}
					theme={props.theme ? 'light' : 'dark'}
					hl={props.lang}
					inject={true}
					onLoad={() => {setCaptchaReady(true);}}
					onVerify={(token) => {
						setToken(token);
						handleSubmit(null)
					}}
					ref={(r) => recaptchaRef = r}/>
				<Row>
					<Button color="primary" type="submit" className={isLoading ? 'button is-loading' : ''} onClick={handleSubmit}>
						{i18n.t('contact.fields.send')}
					</Button>
				</Row>
			</Form>
		</Container>
	)
}
export default Contact;
