import React from 'react'

import HeaderProfile from 'src/components/Headers/HeaderProfile'
import HeaderBasePage from 'src/components/Headers/HeaderBasePage'
import i18n from 'src/i18n'
import BasePage from 'src/components/BasePage'
import About from 'src/components/Main/About'
import Experiences from 'src/components/Main/Experiences'
import Educations from 'src/components/Main/Educations'
import Contact from 'src/components/Main/Contact'
import Home from 'src/components/Main/Home'
import HeaderHome from 'src/components/Headers/HeaderHome'
import { Route, Switch } from 'react-router'
import { ToastContainer } from 'react-toastify'
import Skills from 'src/components/Main/Skills'

/**
 * @return {null}
 */
function App (props, context) {
	const [pills, setPills] = React.useState(-1)
	const [pillsEducation, setPillsEducation] = React.useState(-1)
	const [theme, setTheme] = React.useState(true);

	const companies = Object.keys(i18n.getResource(i18n.language, 'translation', 'experiences.companies'))
	const schools = Object.keys(i18n.getResource(i18n.language, 'translation', 'educations.schools'))

	const [lang, setLang] = React.useState('fr');

	React.useEffect(() => {
		setTheme(!window.matchMedia('(prefers-color-scheme: dark)').matches);

		i18n.on('languageChanged', (_lang) => {
			_lang = _lang.split('-')[0]
			setLang(_lang)
		});

		languageChanged(props.lang);

		return () => {
			i18n.off('languageChanged', (_lang) => {
				_lang = _lang.split('-')[0]
				setLang(_lang)
			});
		}
	}, []);

	const languageChanged = async (_lang) => {
		await i18n.changeLanguage(_lang, () => {
			setLang(_lang)
		});
	}

	return (
		<Switch>
			<Route
				path={'/about'}
				render={() =>
					<BasePage
						theme={theme}
						setTheme={setTheme}
						languageChanged={languageChanged}
						lang={lang}
						className={'profile-page'}
						mainComponent={About}
						header={HeaderProfile}
					/>}
			/>
			<Route
				path={'/skills'}
				render={() =>
					<BasePage
						theme={theme}
						setTheme={setTheme}
						languageChanged={languageChanged}
						lang={lang}
						className={'skills-page'}
						mainComponent={Skills}
						type={'skills'}
						header={HeaderBasePage}
					/>}
			/>
			<Route
				path={'/experiences/:company?'}
				render={(props) => {
					setPills(props.match.params.company ? companies.indexOf(props.match.params.company) : -1)
					return <BasePage
						theme={theme}
						setTheme={setTheme}
						languageChanged={languageChanged}
						lang={lang}
						className={'experiences-page'}
						setPills={setPills}
						pills={pills}
						companies={companies}
						type={'experiences'}
						mainComponent={Experiences}
						header={HeaderBasePage}
					/>
				}
				}
			/>
			<Route
				path={'/educations/:school?'}
				render={(props) => {
					setPillsEducation(props.match.params.school ? schools.indexOf(props.match.params.school) : -1)
					return <BasePage
						theme={theme}
						setTheme={setTheme}
						languageChanged={languageChanged}
						lang={lang}
						className={'educations-page'}
						setPills={setPillsEducation}
						pills={pillsEducation}
						companies={schools}
						type={'educations'}
						mainComponent={Educations}
						header={HeaderBasePage}
					/>
				}
				}
			/>
			<Route
				path={'/contact'}
				render={() =>
					<BasePage
						theme={theme}
						setTheme={setTheme}
						languageChanged={languageChanged}
						lang={lang}
						className={'contact-page'}
						type={'contact'}
						mainComponent={Contact}
						header={HeaderBasePage}
					/>}
			/>
			<Route
				path={'/'}
				render={() =>
					<BasePage
						theme={theme}
						setTheme={setTheme}
						languageChanged={languageChanged}
						lang={lang}
						className={'home-page'}
						mainComponent={Home}
						header={HeaderHome}
					/>}
			/>
		</Switch>
	)
}

export default App
