import React from 'react'
import { Row, TabContent, TabPane } from 'reactstrap'
import i18n from 'src/i18n'
import { Helmet } from 'react-helmet'

function Experiences (props) {
	const renderEducation = (school, key) => {
		const href = i18n.t(`educations.schools.${school}.url`);

		return (
			<TabPane tabId={`pillsEducation${key}`} key={key}>
				<div className={'col-md-12'} key={key}>
					<div className={'educations'}>
						<Row>
							<h2 className={'second-color'}>
								<a
									href={href}
									rel={'noopener noreferrer'}
									target={'_blank'}
									className={'second-color'}
								>
									{i18n.t(`educations.schools.${school}.label`)}
								</a>
								{/* eslint-enable */}
							</h2>
						</Row>
						<Row className={'position-date'}>
							<h5 className={'date'}>
								{i18n.t(`educations.schools.${school}.date`)}
							</h5>
						</Row>
						<div className={'col-md-12'}>
							<p className={'educations-details'}>{i18n.t(`educations.schools.${school}.description`)}</p>
							<p className={'educations-details'}>{i18n.t(`educations.schools.${school}.speciality`)}</p>
							<p className={'educations-details'}>{i18n.t(`educations.schools.${school}.more`)}</p>
						</div>
					</div>
				</div>
			</TabPane>
		)
	}

	return (
		<div className={'col-md-10 ml-auto mr-auto'}>
			<Row className={'ml-auto mr-auto experiences'}>
				<TabContent activeTab={'pillsEducation' + props.pills} className={'col-md-12'}>
					{props.companies.map((school, index) =>
						renderEducation(school, index)
					)}
				</TabContent>
			</Row>
		</div>
	)
}

export default Experiences;
